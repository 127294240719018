@tailwind base;
@tailwind components;
@tailwind utilities;


html{
    @apply bg-slate-300 text-gray-800    
}
@media all {
    .page-breaks {
      display: none;
    }
  }
  
  @media print {
    html, body {
      background-color: white;
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media print {
    
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: always !important;
    }
    .no-print{
    display: none !important;
    }
    .hidden-print{
      display: block !important;
      margin-bottom: 10px;
    }
  }
  
  @page {
    size: auto;
    margin: 10mm;
  }
  .Mui-selected{
    background-color: rgb(204, 241, 255) !important;
  }
.hidden-print{
  display: none;
}

.ip-table{
  @apply w-full mt-5 overflow-x-auto
}
.ip-table th{
  @apply font-semibold uppercase border border-gray-800 p-1 text-[11px] bg-slate-400
}
.ip-table td{
  @apply capitalize border border-gray-800 p-1 text-[11px]
}
.ip-table tr{
 @apply hover:bg-slate-200
}

.table-print tr{
 @apply hover:bg-slate-300
}
.table-print{
  @apply w-full mt-5 overflow-x-auto text-[11px]
}
.table-print th{
  @apply uppercase border border-gray-800 p-1 text-[11px] bg-blue-500 text-white
}
.table-print td{
  @apply border border-gray-800 p-1 text-[11px] uppercase
}
.table-print-2{
  @apply min-w-full divide-y divide-gray-200
}
.table-print-2 tr{
 @apply hover:bg-slate-300
}
.table-print-2{
  @apply w-full mt-5 overflow-x-auto text-[11px]
}
.table-print-2 th{
  @apply uppercase border border-gray-800 p-1 text-[11px] bg-indigo-500 text-white
}
.table-print-2 td{
  @apply border border-gray-800 p-1 text-[11px] uppercase
}
.table-print-2{
  @apply min-w-full divide-y divide-gray-200
}

.table-print-3{
  @apply min-w-full divide-y divide-gray-200
}
.table-print-3 tr{
 @apply hover:bg-green-300
}
.table-print-3{
  @apply w-full mt-5 overflow-x-auto text-[11px]
}
.table-print-3 th{
  @apply uppercase border border-gray-800 p-1 text-[11px] bg-gray-500 text-white
}
.table-print-3 td{
  @apply border border-gray-800 p-1 text-[11px] uppercase
}
.table-print-3{
  @apply min-w-full divide-y divide-gray-200
}
 
.swal2-container {
  z-index: 1500 !important;
} 

.table-head{
  @apply w-full overflow-x-auto text-[11px]
}
.table-head th{
  @apply uppercase  border-none px-1 text-[11px] text-left
}
.table-head td{
  @apply  border-none px-1 text-[11px] uppercase
}
.ps-menu-label{
  white-space: normal !important;
}
.ps-menu-button{
  padding-left: 30px;
}
.ps-sidebar-root{
  position: fixed !important;
}
.codigo::before {
  content: attr(data-text);
  display: block;
  white-space: pre;
  width: 150px;
  overflow-wrap: break-word;
}

.codigo::after {
  content: attr(data-text);
  display: block;
  white-space: pre;
  width: 150px;
  overflow-wrap: break-word;
  transform: translateX(100%);
  margin-left: -1.5em;
  margin-right: 1.5em;
}

.codigo::after {
  content: "-" attr(data-text);
}

@media (max-width: 600px) {
  .my-button-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1px;
    white-space: normal;
  }
}
#loader{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.711);
  z-index: 99999;
}
#loader2{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.711);
  z-index: 99999;
}
.spinnerContainer {  
}


.spinner {
  width: 56px;
  height: 56px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #0e6ce0;
  animation: tri-spinner 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: tri-spinner 2s infinite;
}

.spinner::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes tri-spinner {
  100% {
    transform: rotate(1turn);
  }
}

.load{
 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader {
  
  color: #fffdfd;
  font-family: "Poppins",sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
  
}

.words {
  overflow: hidden;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #0e6ce0;
  animation: cycle-words 5s infinite;
}

@keyframes cycle-words {
  10% {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-205%);
    transform: translateY(-205%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-305%);
    transform: translateY(-305%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-405%);
    transform: translateY(-405%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}

.table-conta{
  @apply w-full mt-5 overflow-hidden rounded-lg border border-gray-300 text-xs
}
.table-conta th{
  @apply font-bold bg-gray-200 uppercase px-1 py-2 border border-gray-300 text-gray-800
}
.table-conta td{
  @apply  uppercase px-1 py-1 border-b border-gray-300
}
